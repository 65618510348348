export const InputEmail = ({
  name,
  placeholder,
  register,
  errors,
  requiredText,
  ...props
}) => {
  return (
    <>
      <input
        type="email"
        {...register(name ? name : "email", {
          required: {
            value: true,
            message: requiredText || "Email is required",
          },
          pattern: {
            // Value: /(^(?:[0-9]+[a-z]|[a-z]+[0-9]|[a-z])[a-z0-9._%+-]*)+@[a-z0-9.-]+\.[a-z]{2,}$/,
            value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
            message: "Invalid e-mail",
          },
        })}
        placeholder={placeholder || "Email*"}
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        className="w-full border-0 border-b border-b-gray-300 p-2 text-bbc-gray placeholder-gray-300 focus:ring-0 "
        autoComplete="off"
        {...props}
      />
      <span className="inline-block pt-2 text-left text-sm text-red-500">
        {name ? errors[name]?.message : errors.email?.message}
      </span>
    </>
  );
};
// Text Field must be single word or multiple word space with "_"
export const InputText = ({
  as,
  name,
  placeholder,
  register,
  errors,
  requiredText,
  ...props
}) => {
  const CustomTag = as || "input";

  return (
    <>
      <CustomTag
        type={"text"}
        {...register(name, {
          required: {
            value: true,
            message: requiredText || "This field is required",
          },
        })}
        placeholder={placeholder}
        className="invalid-textfield w-full border-0 border-b border-b-gray-300 p-2 text-bbc-gray placeholder-gray-300 focus:ring-0"
        autoComplete="off"
        {...props}
      />
      {errors[name] && (
        <span className="inline-block pt-2 text-sm text-red-500">
          {errors[name]?.message}
        </span>
      )}
    </>
  );
};
export const InputPhone = ({
  name,
  placeholder,
  register,
  errors,
  requiredText,
  invalidText,
  ...props
}) => {
  return (
    <>
      <input
        type="text"
        {...register(name || "phone_number", {
          required: {
            value: true,
            message: requiredText || "Phone number is required",
          },
          pattern: {
            value: /[0-9]+/,
            message: invalidText || "Invalid phone number",
          },
        })}
        placeholder={placeholder}
        pattern="[0-9]+"
        className="invalid-textfield w-full border-0 border-b border-b-gray-300 p-2 text-bbc-gray placeholder-gray-300 focus:ring-0"
        autoComplete="off"
        {...props}
      />
      <span className="inline-block pt-2 text-sm text-red-500">
        {name ? errors[name]?.message : errors.phone_number?.message}
      </span>
    </>
  );
};

export const InputNumber = ({ numberValue, min, max, setNumberValue }) => {
  const handleOnclickNumber = (number) => {
    setNumberValue(number);
  };

  return (
    <>
      <div className="relative flex w-28 items-center lg:mb-6 lg:w-40">
        <button
          className="absolute left-2 w-5 lg:left-5 "
          onClick={(event) => {
            if (numberValue > min) {
              handleOnclickNumber(parseInt(numberValue, 10) - 1);
            }
            event.preventDefault();
          }}
        >
          -
        </button>
        <button
          className="absolute right-2 w-5 lg:right-5 "
          onClick={(event) => {
            if (numberValue < max) {
              handleOnclickNumber(parseInt(numberValue, 10) + 1);
            }
            event.preventDefault();
          }}
        >
          +
        </button>
        <input
          className="moz:appearance-none h-10 w-28 rounded-full border-zinc-200 text-center lg:w-40"
          type="text"
          value={numberValue}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          min={min}
          onChange={(event) => {
            event.preventDefault();
            const newValue = event.target.value;

            if (newValue === "") {
              setNumberValue("");
            } else if (newValue < min) {
              setNumberValue(min);
            } else if (newValue > max) {
              setNumberValue(max);
            } else {
              setNumberValue(newValue);
            }
          }}
          onBlur={() => {
            if (numberValue < min) {
              setNumberValue(min);
            }
          }}
        />
      </div>
    </>
  );
};

export const CheckBox = ({
  name,
  text,
  register,
  errors,
  checkboxTextClassName,
  ...props
}) => {
  return (
    <>
      <div className="relative block pl-7">
        <input
          type="checkbox"
          {...register(name, {})}
          className="bbc-checkbox absolute left-1 top-1 cursor-pointer rounded border-bbc-silver bg-transparent focus:shadow-none focus:outline-none focus:ring-0"
          {...props}
        />
        <div
          className={checkboxTextClassName ? checkboxTextClassName : ""}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </div>
      {errors[name] && (
        <span className="inline-block pt-2 text-sm text-red-500">
          {errors[name]?.message}
        </span>
      )}
    </>
  );
};
