export default function trigger(eventType, data, fullName) {
  const event = new CustomEvent(eventType, {
    detail: {
      dataForm: {
        fullname: fullName,
        email: data.email,
        ...data,
      },
      response: data,
    },
  });

  try {
    document.dispatchEvent(event);
  } catch (error) {
    console.log(error);
  }
}
