import { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";

import { ToastContext } from "common/contexts";

import Img from "components/atoms/Img";

import ButtonNew from "../ButtonNew/index";
import { FaTimes } from "../Icon/fa/FaTimes";

import styles from "./Index.module.scss";

const contentStatus = {
  success: {
    title: "Send Success",
    description: "We'll contact you all right away.",
  },
  error: {
    title: "Send Error",
    description: "Please check your network connection and try again",
  },
};

let timer = null;

const RenderContent = ({ sendStatus, sendContent, setIsOpen }) => {
  switch (sendStatus) {
    case "success": {
      return (
        <>
          {/* <ProgressBar timer={timer} color={`bg-bbc-green-500`} /> */}
          <div className="">
            <Img
              src="/assets/icons/checked.png"
              width={90}
              height={92}
              alt="icon success"
              className="mb-6"
            />
          </div>
          <p className="mb-6 text-2xl font-semibold text-bbc-brand-800">
            {sendContent?.title}
          </p>
          <p className="text-sm text-bbc-gray">{sendContent?.description}</p>
        </>
      );
    }
    case "error": {
      return (
        <>
          {/* <ProgressBar timer={timer} color={`bg-red-500`} /> */}
          <Img
            src="/assets/icons/error.png"
            width={90}
            height={92}
            alt="icon error"
            className="mb-6"
          />
          <p className="mb-6 text-2xl font-semibold text-bbc-red-500">
            {sendContent?.title}
          </p>
          <p className="text-sm text-bbc-red-500">{sendContent?.description}</p>
        </>
      );
    }
    case "confirm": {
      return (
        <>
          <Img
            src="/assets/icons/warning.png"
            width={98}
            height={100}
            alt="icon error"
            className="mx-auto mb-6"
            wrapper={{
              className: "block",
            }}
          />
          <p className="mb-6 text-2xl font-semibold text-bbc-brand-700">
            {sendContent?.title}
          </p>
          <div className="flex justify-around">
            <ButtonNew
              variant="outline"
              btnTextColor={"text-bbc-red-300 hover:text-white"}
              btnBorder={"border border-bbc-red-300 hover:border-bbc-red-400"}
              btnColor={"bg-transparent hover:bg-bbc-red-400"}
              othersClass={"w-28"}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              No
            </ButtonNew>
            <ButtonNew
              othersClass={"w-28"}
              onClick={() => {
                sendContent.onClick();
                setIsOpen(false);
              }}
            >
              Yes
            </ButtonNew>
          </div>
        </>
      );
    }
    case "custom": {
      return sendContent.element;
    }
    default:
      return (
        <>
          <p className="mb-6 text-2xl font-semibold text-bbc-red-600">
            {contentStatus.error.title}
          </p>
          <p className="text-sm">{contentStatus.error.description}</p>
        </>
      );
  }
};

export default function Toast({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [sendStatus, setSendStatus] = useState("");
  const [sendContent, setSendContent] = useState("");
  const [bgAlert, setBgAlert] = useState("");
  /* Handle Event */
  const closeModal = (otherAction) => {
    setIsOpen(false);
    if (otherAction) {
      try {
        otherAction();
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    clearTimeout(timer);
    document.documentElement.removeAttribute("style");
  };

  const openAlert = async (time, status, content, otherAction) => {
    if (content) {
      setSendContent(content);
    } else {
      setSendContent(contentStatus[status]);
    }
    switch (status) {
      case "success": {
        setBgAlert("from-[#8CEBFE] to-[#F9F9F9]");
        break;
      }
      case "error": {
        setBgAlert("from-[#dd3333] to-[#F9F9F9]");
        break;
      }
      case "confirm": {
        setBgAlert("from-[#8CEBFE] to-[#F9F9F9]");
        break;
      }
      case "custom": {
        setBgAlert(sendContent.bgAlert || "from-[#8CEBFE] to-[#F9F9F9]");
        break;
      }

      default:
        break;
    }
    setSendStatus(status);
    setIsOpen(true);

    function closeModalAfterTime(timeToClose) {
      return new Promise((resolve) => {
        timer = setTimeout(() => {
          closeModal(otherAction);
          resolve("closed");
        }, timeToClose);
      });
    }

    if (time) {
      await closeModalAfterTime(time);
    }
  };

  /* Effect */

  return (
    <ToastContext.Provider value={{ openAlert, setOpenAlert: setIsOpen }}>
      {children}
      {/* <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={() => {
            openAlert(3000);
          }}
          className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button>
      </div> */}

      <Transition show={isOpen} as={"div"}>
        <Dialog
          open={isOpen}
          as="div"
          className="fixed inset-0  overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 100 }}
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-slate-900 bg-opacity-70" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`${styles.ToastContent} relative inline-flex w-full flex-col  justify-center border-2 bg-gradient-to-b px-7  py-28 text-center align-middle transition-all ${bgAlert} rounded-2xl shadow-xl`}
              >
                <div className={"absolute top-6 right-4"}>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-full border-2 border-white p-1 text-xs  font-medium text-white transition-all  hover:border-bbc-red-300 hover:text-bbc-red-300 focus:outline-none "
                    onClick={closeModal}
                  >
                    <FaTimes size={12} />
                  </button>
                </div>

                <div className="relative ">
                  <RenderContent
                    sendContent={sendContent}
                    sendStatus={sendStatus}
                    setIsOpen={setIsOpen}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </ToastContext.Provider>
  );
}
