import { useContext, useRef } from "react";
import { useForm } from "react-hook-form";

import PropTypes from "prop-types";

import { LayoutContext, ToastContext } from "common/contexts";
import getGAUserID from "common/fetch/getGAUserID";
import sendData from "common/fetch/post";
import trigger from "common/trigger";

import ButtonNew from "components/atoms/ButtonNew/index";
import { BiLoaderAlt } from "components/atoms/Icon/bi/BiLoaderAlt";
import { InputEmail } from "components/atoms/Input/index";
import ReCaptcha from "components/atoms/Recaptcha/index";
import Title from "components/atoms/Title";
import Toast from "components/atoms/Toast";

/** ========== */
const FormNewsLetter = ({
  apiLink,
  triggerName,
  buttonText,
  descriptionBellow,
}) => {
  const { locationRouter } = useContext(LayoutContext);
  const { openAlert } = useContext(ToastContext);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm();

  const recaptchaRef = useRef();

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      const GAUserID = getGAUserID();

      data.client_id = GAUserID ? GAUserID : "";
      data.website_id = process.env.NEXT_PUBLIC_WEBSITE_ID;
      if (!apiLink) {
        data.contact_from_id = 38;

        switch (locationRouter) {
          case "hk":
            data.company_country_id = 98;
            break;
          case "sg":
            data.company_country_id = 191;
            break;
          case "vn":
            data.company_country_id = 244;
            break;
          default:
            break;
        }
      }
      const api = apiLink || "api/portal/contact_form/subscribe";

      try {
        sendData({
          api: `${process.env.NEXT_PUBLIC_ORDER_API_ENDPOINT}/${api}`,
          data,
          token,
        })
          .then((res) => {
            if (res.state) {
              trigger("contact_form", data, triggerName || "Contact form");
              openAlert(2000, "success");
              reset();
            } else {
              openAlert(3000, "error");
              console.log("error");
            }
          })
          .catch((e) => {
            openAlert(3000, "error");
            console.log("error", e);
          });
      } catch (e) {
        clearErrors();
        openAlert(3000, "error");
        console.log("error", e);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto flex flex-col text-center"
      style={{ maxWidth: "450px" }}
    >
      <InputEmail
        register={register}
        errors={errors}
        placeholder="Email"
        className="w-full border border-bbc-neutral-600 py-2 px-4 text-bbc-gray"
        style={{ borderRadius: "49px" }}
      />

      <div className="invisible h-0 w-0">
        <ReCaptcha ref={recaptchaRef} />
      </div>
      {descriptionBellow}
      <ButtonNew
        type="submit"
        othersClass="relative mt-3 mx-auto w-full lg:w-auto"
        style={{ minWidth: "170px" }}
        disabled={isSubmitting}
      >
        {isSubmitting && (
          <BiLoaderAlt className="mr-2 inline-block animate-spin" size={20} />
        )}
        {buttonText || "Subscribe"}
      </ButtonNew>
    </form>
  );
};

const FormNewsletter = ({
  bgColor,
  title,
  description,
  descriptionBellow,
  apiLink,
  triggerName,
  buttonText,
}) => {
  return (
    <section>
      <div className="container">
        <div
          className={`${
            bgColor ? bgColor : "bg-bbc-gradient-3"
          } rounded-[20px] py-11 px-6`}
          style={{ boxShadow: "0px 4px 16px rgba(37, 37, 37, 0.25)" }}
        >
          <div className="mx-auto max-w-[880px]">
            <Title
              text={title || "Get helpful tips and info from our newsletter!"}
              className="mb-6 text-center text-bbc-30px font-semibold capitalize text-white lg:text-bbc-40px"
            />
            <p className="mx-auto  mb-6 max-w-lg text-center text-white">
              {description ||
                "Stay in the know and be empowered with our strategic how-tos, resources, and guidelines."}
            </p>
            <Toast>
              <FormNewsLetter
                apiLink={apiLink}
                triggerName={triggerName}
                buttonText={buttonText}
                descriptionBellow={descriptionBellow}
              />
            </Toast>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormNewsletter;

FormNewsletter.propTypes = {
  bgColor: PropTypes.string,
};

FormNewsletter.defaultProps = {};

/** ========== */
