const getGAUserID = () => {
    try {
        var trackers = ga.getAll();
        var i, len;
        for (i = 0, len = trackers.length; i < len; i += 1) {
            if (trackers[i].get("trackingId") === process.env.NEXT_PUBLIC_GA_ID) {
                return trackers[i].get("clientId");
            }
        }
    } catch (e) { }
    return false;
};
export default getGAUserID;