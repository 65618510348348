import { forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import LazyLoad from "react-lazyload";
const ReCaptcha = (props, ref) => {
  return (
    <LazyLoad>
      <ReCAPTCHA
        ref={ref}
        sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA_V3_SITE_KEY}
        badge="inline"
        size="invisible"
        {...props}
      />
    </LazyLoad>
  );
};

export default forwardRef(ReCaptcha);
