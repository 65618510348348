const sendData = async ({ api, data, token }) => {
  const endpoint = api;
  const headers = {
    "Content-Type": "application/json",
    "captcha-response": token,
  };
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  const response = await fetch(endpoint, options);
  return await response.json();
};

export default sendData;
